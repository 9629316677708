import React, { useEffect } from 'react';

// Path helper for public assets
const publicUrl = process.env.PUBLIC_URL || '';

function HomePage() {
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = `${publicUrl}/js/jquery-3.2.1.slim.min.js`;
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.src = `${publicUrl}/js/bootstrap.bundle.min.js`;
    script2.async = true;
    document.body.appendChild(script2);

    const script3 = document.createElement('script');
    script3.src = `${publicUrl}/js/aos.js`;
    script3.async = true;
    document.body.appendChild(script3);

    script3.onload = () => {
      if (window.AOS) {
        window.AOS.init({});
      }
    };

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
    };
  }, []);

  return (
    <>
      <div className="hero-banner">
        <div className="container">
          <div className="header">
            <div className="logo">
              <img src={`${publicUrl}/img/logo_wavz.svg`} alt="WAVZ" />
            </div>
            <div className="nav-menu">
              <ul>
                <li>
                  <a href="https://www.wavzapp.net/privacy-policy.html" target="_blank" rel="noopener noreferrer">
                    Privacy
                  </a>
                </li>
                <li>
                  <a href="https://www.wavzapp.net/terms.html" target="_blank" rel="noopener noreferrer">
                    Terms
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="banner-content">
            <h1>Welcome to WAVZ App</h1>
            <p>Identify authorities, safety responders, hazards and find your friends.</p>
            <div className="download">
              <a href="https://apps.apple.com/app/wavz/id1660778558" target="blank">
                <img src={`${publicUrl}/img/app_store_badge.svg`} alt="WAVZ" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.wavz.app" target="blank">
                <img src={`${publicUrl}/img/google_play_badge.svg`} alt="WAVZ" />
              </a>
            </div>
          </div>
          <div className="cloud-lg">
            <img src={`${publicUrl}/img/cloud_lg.svg`} alt="WAVZ" />
          </div>
          <div className="cloud-sm">
            <img src={`${publicUrl}/img/cloud_sm.svg`} alt="WAVZ" />
          </div>
          <div className="boat-blue">
            <img src={`${publicUrl}/img/boat_blue.svg`} alt="WAVZ" />
          </div>
          <div className="boat-red">
            <img src={`${publicUrl}/img/boat_red.svg`} alt="WAVZ" />
          </div>
        </div>
      </div>
      <div className="section-about">
        <div className="container">
          <div className="about-inner">
            <div className="img-sec" data-aos-once="true">
              <img src={`${publicUrl}/img/about_app_graphics.svg`} alt="WAVZ" />
            </div>
            <div className="content-sec">
              <h1 data-aos="fade-up" data-aos-once="true">About this App</h1>
              <p data-aos="fade-down" data-aos-once="true">
                Travel-enthusiasts, come hither! The WAVZ app for iOS and Android paves the way for hassle-free travel on land
                and water. Pin the presence of Hazards, Police and Points of Interest (POI) on the map to notify other users
                when the pins come within their notification range. Upvote and downvote on Hazard and Police pins to eventually
                retain only authentic pins on the map. Enjoy your journeys for a long time to come!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-features">
        <div className="container">
          <h1 data-aos="fade-up" data-aos-once="true">Features</h1>
          <div className="features-inner">
            <div className="content-sec">
              <div className="feature-info">
                <div className="feature-ico" data-aos="fade-up" data-aos-once="true">
                  <img src={`${publicUrl}/img/icon_add_pin.svg`} alt="WAVZ" />
                </div>
                <div className="feature-content" data-aos="fade-down" data-aos-once="true">
                  <h4>Add Pins</h4>
                  <p>
                    Pin the location of Hazards, Police and Points of Interest (POI) on the map to notify other users when they
                    come within the notification range of such pins.
                  </p>
                </div>
              </div>
              <div className="feature-info">
                <div className="feature-ico" data-aos="fade-up" data-aos-once="true">
                  <img src={`${publicUrl}/img/icon_timeline.svg`} alt="WAVZ" />
                </div>
                <div className="feature-content" data-aos="fade-down" data-aos-once="true">
                  <h4>Timeline</h4>
                  <p>Record the path of your travel to view it again on the map in future.</p>
                </div>
              </div>
              <div className="feature-info">
                <div className="feature-ico" data-aos="fade-up" data-aos-once="true">
                  <img src={`${publicUrl}/img/icon_settings.svg`} alt="WAVZ" />
                </div>
                <div className="feature-content" data-aos="fade-down" data-aos-once="true">
                  <h4>App Settings</h4>
                  <p>Configure your app settings to customize your map view.</p>
                </div>
              </div>
              <div className="feature-info">
                <div className="feature-ico" data-aos="fade-up" data-aos-once="true">
                  <img src={`${publicUrl}/img/icon_sos.svg`} alt="WAVZ" />
                </div>
                <div className="feature-content" data-aos="fade-down" data-aos-once="true">
                  <h4>SOS</h4>
                  <p>Set the SOS option to call your emergency contact when in need.</p>
                </div>
              </div>
              <div className="feature-info">
                <div className="feature-ico" data-aos="fade-up" data-aos-once="true">
                  <img src={`${publicUrl}/img/icon_friends.svg`} alt="WAVZ" />
                </div>
                <div className="feature-content" data-aos="fade-down" data-aos-once="true">
                  <h4>Friends</h4>
                  <p>Make other users your friends and connect with them for exciting jaunts.</p>
                </div>
              </div>
            </div>
            <div className="img-sec" data-aos="fade-down" data-aos-once="true">
              <img src={`${publicUrl}/img/feature_app_graphics.svg`} alt="WAVZ" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-footer">
        <div className="social-media">
          <ul>
            <li>
              <a href="/#" data-bs-toggle="modal" data-bs-target="#ComingsoonModal">
                <img src={`${publicUrl}/img/icon_facebook.svg`} alt="WAVZ" />
              </a>
            </li>
            <li>
              <a href="/#" data-bs-toggle="modal" data-bs-target="#ComingsoonModal">
                <img src={`${publicUrl}/img/icon_instagram.svg`} alt="WAVZ" />
              </a>
            </li>
            <li>
              <a href="/#" data-bs-toggle="modal" data-bs-target="#ComingsoonModal">
                <img src={`${publicUrl}/img/icon_linkedin.svg`} alt="WAVZ" />
              </a>
            </li>
            <li>
              <a href="/#" data-bs-toggle="modal" data-bs-target="#ComingsoonModal">
                <img src={`${publicUrl}/img/icon_twitter.svg`} alt="WAVZ" />
              </a>
            </li>
          </ul>
        </div>
        <p>Copyright © 2023 WAVZ. All rights reserved.</p>
      </div>
      <div className="modal fade" id="ComingsoonModal" tabIndex="-1" aria-labelledby="ComingsoonModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="logo">
                <img src={`${publicUrl}/img/logo_wavz.svg`} alt="WAVZ" />
              </div>
              <h1>Coming Soon!</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
