import React from 'react';
import { Link } from 'react-router-dom';
import './NotFoundPage.css'; 

const publicUrl = process.env.PUBLIC_URL || '';

function NotFoundPage() {
  return (
    <div className="not-found-container">
      <img src={`${publicUrl}/logo_mobile.svg`} alt="Logo" className="logo" style={{"max-width": "150px", "margin-bottom": "20px"}} />
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <Link to="/delete-my-account" className="back-to-home-button">Back to Home</Link>
    </div>
  );
}

export default NotFoundPage;
