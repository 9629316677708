import React,{ useEffect  }  from 'react';
import { useNavigate } from 'react-router-dom';
import './DeleteMyAccount.css';

const publicUrl = process.env.PUBLIC_URL || '';

const DeleteMyAccount = () => {
    const navigate = useNavigate();

    useEffect(() => {
      document.title = 'WAVZ: Delete Account';
    }, []);

    const handleDelete = () => {
        navigate('/account-delete-request');
      };      

  return (
    <div className="mobile-container">
      <div className="logo">
        <img src={`${publicUrl}/logo_mobile.svg`} alt="Logo" />
      </div>
      <h2>Delete My Account</h2>
      <div className="trash">
        <img src="/trash-icon.png" alt="Trash Icon" />
      </div>
      <h1>Deleting your account can't be undone.</h1>
      <h3>Upon requesting to delete your account:</h3>
      <ul>
        <li>
          We will remove all your details from the app, including your account info, your contributions and recorded timelines, following which you will be unable to access your account.
        </li>
        <li>Your friends will no longer be able to view your profile and interact further.</li>
      </ul>
      <div className="delete-wrap">
        <h4>Are you sure you want to delete your account?</h4>
        <button type="button" className="btn-delete" onClick={handleDelete}>
          Delete Account
        </button>
      </div>
    </div>
  );
};

export default DeleteMyAccount;
