import React, { useState, useEffect, useRef   } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import './AccountDeleteRequest.css';

const publicUrl = process.env.PUBLIC_URL || '';

const AccountDeleteRequest = () => {
  const [formData, setFormData] = useState({
    name: '',
    emailId: '',
    phoneNumber: '',
    signInMechanism: 'Apple',
    recaptchaToken: ''
  });
  const [recaptchaError, setRecaptchaError] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [namemessage, setnameMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRef(null);
  const nameInputRef = useRef(null);

  useEffect(() => {
    document.title = 'WAVZ: Delete Account';
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleRecaptchaChange = (token) => {
    setFormData({
      ...formData,
      recaptchaToken: token
    });
    setRecaptchaError(''); // Clear error on successful reCAPTCHA
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setnameMessage('');
    setIsLoading(true);

    const trimmedName = formData.name.trim();
    if (trimmedName === '') {
      setnameMessage('Enter Name');
      setMessageType('error');
      setIsLoading(false);
      setFormData({ ...formData, name: '' });
      nameInputRef.current.focus();
      return;
    }

    if (!formData.recaptchaToken) {
      setRecaptchaError('Please verify that you are not a robot.');
      setIsLoading(false);
      return;
    }

    try {
      // console.log('Form Data:', formData);
      const response = await axios.post('https://api.wavzapp.net/api/user/DeleteAccountFromAppStore', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.errorCode === 0) {
        setMessage('Your request to delete your account has been submitted.');
        setMessageType('success');
        resetForm();
      } else {
        setMessage(response.data.message);
        setMessageType('error');
      }

      // console.log('Response:', response.data);

    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred while submitting the request.');
      setMessageType('error');
    }
    finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      emailId: '',
      phoneNumber: '',
      signInMechanism: 'Apple',
    });
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  return (
    <div className="mobile-container">
      <div className="logo">
        <img src={`${publicUrl}/logo_mobile.svg`} alt="Logo" />
      </div>
      <h2>Submit Account Delete Request</h2>
      {message && <div className={`response-message ${messageType === 'success' ? 'success' : 'error'}`}>{message}</div>}
      <form onSubmit={handleSubmit} className="form-sec">
        <div className="form-group">
          <label htmlFor="name">Name<span>*</span></label>
          <input
            type="text"
            className="form-input"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            maxLength="50"
            ref={nameInputRef}
          />
          {namemessage && <div className={`response-message ${messageType === 'success' ? 'success' : 'error'}`}>{namemessage}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="emailId">Email<span>*</span></label>
          <input
            type="email"
            className="form-input"
            id="emailId"
            name="emailId"
            value={formData.emailId}
            onChange={handleChange}
            required
            maxLength="150"
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number (optional)</label>
          <input
            type="text"
            className="form-input"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            maxLength="15"
          />
        </div>
        <div className="form-group">
          <label htmlFor="signInMechanism">Sign In mechanism used</label>
          <select
            name="signInMechanism"
            id="signInMechanism"
            className="form-input"
            value={formData.signInMechanism}
            onChange={handleChange}
            required
          >
            <option value="Apple">Apple</option>
            <option value="Email">Email</option>
            <option value="Facebook">Facebook</option>
            <option value="Google">Google</option>
          </select>
        </div>
        <div className="form-group">
          <ReCAPTCHA
            sitekey="6LeFDOcpAAAAAML5Y6aerLNRXyIWtwc4PysWqTw3"
            onChange={handleRecaptchaChange}
            ref={recaptchaRef}
          />
          {recaptchaError && <span className="error">{recaptchaError}</span>}
        </div>
        <div className="submit-container">
        {isLoading && (
          <img
            src="/loader.gif"
            alt="Loading..."
            className="loader"
            style={{ width: '50px', height: '50px' }}
          />
        )}
        {!isLoading && (
          <button type="submit" className="btn-delete">
            Submit
          </button>
        )}
      </div>
        
      </form>
    </div>
  );
};

export default AccountDeleteRequest;
