import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DeleteMyAccount from './DeleteMyAccount';
import AccountDeleteRequest from './AccountDeleteRequest';
import HomePage from './HomePage';
import NotFoundPage from './NotFoundPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} /> 
        <Route path="/account-delete-request" element={<AccountDeleteRequest />} />
        <Route path="/delete-my-account" element={<DeleteMyAccount />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
